import React, { memo, useContext } from 'react';
import classes from '../../styles/pagination.module.css';

import { ThemeContext } from '../../Context/ThemeContext';

const Btn = ({ Fn, img, txt, txtColor, btnBgColor, btnHomeResults, btnStatus = true, opacity = true }) => {
    const { theme } = useContext(ThemeContext);

    return btnStatus ? (
        <div className={`${classes.exportBtn} btnok_${theme} ${!opacity ? classes.exportBtn_op : ''}`} onClick={Fn}>
            {img && <img alt='' src={img} width={24} height={24} />}
            <div className={classes.importTxt}>{txt}</div>
        </div>
    ) : btnHomeResults ? <div className={`${classes.exportBtn} btnHomeResults_${theme} ${!opacity ? classes.exportBtn_op : ''}`} onClick={Fn}>
        {img && <img alt='' src={img} width={24} height={24} />}
        <div className={classes.importTxt}>{txt}</div>
    </div> : <div className={`${classes.exportBtn} btncancel_${theme} ${!opacity ? classes.exportBtn_op : ''}`} onClick={Fn}>
        {img && <img alt='' src={img} width={24} height={24} />}
        <div className={classes.importTxt}>{txt}</div>
    </div>;
}

export default memo(Btn);
