import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../interceptor";

export const getReportDetailsAction = createAsyncThunk("getReportDetails", async (id) => {
    try {
        const result = await axiosInstance.get(`Incidents/${id}`);
        return result.data;
    } catch (e) {
        console.log(e);
        return e;
    }
});

export const assignMissionToRanger = createAsyncThunk("assignMission", async ({id, rangerId}) => {
    try {
        const result = await axiosInstance.put(`Missions/${id}/assign`, {assignToId: rangerId});
        return result.data;
    } catch (e) {
        console.log(e);
        return e;
    }
});

export const cancelMission = createAsyncThunk("cancelMission", async (id) => {
    try {
        const result = await axiosInstance.put(`Missions/${id}/cancel`);
        return result.data;
    } catch (e) {
        console.log(e);
        return e;
    }
});

export const addComment = createAsyncThunk("addComment", async ({id, content}) => {
    try {
        const result = await axiosInstance.post(`Missions/${id}/note`, [{content: content}]);
        return result.data;
    } catch (e) {
        console.log(e);
        return e;
    }
});

const reportDetailsSlice = createSlice({
    name: "reportDetails",
    initialState: { reportDetails: {}, isLoading: true, isError: false, isAssignLoading: false, isCancelLoading: false },
    extraReducers: (builder) => {
        builder.addCase(getReportDetailsAction.fulfilled, (state, action) => {
            state.reportDetails = action.payload;
            state.isLoading = false;
            state.isError = false;
        });
        builder.addCase(getReportDetailsAction.rejected, (state, action) => {
            state.isError = true;
        });
        builder.addCase(assignMissionToRanger.pending, (state, action)=>{
            state.isAssignLoading = true;
        });
        builder.addCase(assignMissionToRanger.fulfilled, (state, action)=>{
            state.isAssignLoading = false;
        });
        builder.addCase(cancelMission.pending, (state, action)=>{
            state.isCancelLoading = true;
        });
        builder.addCase(cancelMission.fulfilled, (state, action)=>{
            state.isCancelLoading = false;
        });
    },
    reducers: {
        setReportDetailsNull : (state, action) => {
            state.reportDetails = {};
            state.isLoading = true
        }
    }
});

export const {setReportDetailsNull} = reportDetailsSlice.actions;

export default reportDetailsSlice.reducer;