import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../interceptor";

export const getUsersAction = createAsyncThunk("getUsers", async () => {
    try {
        const result = await axiosInstance.get('Users');
        return result.data;
    } catch (e) {
        return Promise.reject({
            message: e.message,
            code: e.code,
        });
    }
});

export const addUserAction = createAsyncThunk("addUser", async (user) => {
    try {
        console.log(user);
        const result = await axiosInstance.post("Users/register", user);
        return result.data;
    } catch (e) {
        console.log(e);
    }
});

export const editUserAction = createAsyncThunk("editUser", async ({userId, user}) => {
    try {
        console.log(user);
        const result = await axiosInstance.put(`Users/${userId}/update-user-info`, user);
        return result.data;
    } catch (e) {
        console.log(e);
    }
});


const usersSlice = createSlice({
    name: "users",
    initialState: { users: [], isLoading: true, isError: false, error: null },
    extraReducers: (builder) => {
        builder.addCase(getUsersAction.fulfilled, (state, action) => {
            state.users = action.payload;
            state.isLoading = false;
            state.isError = false;
            state.error = null;
        });
        builder.addCase(getUsersAction.rejected, (state, action) => {
            state.isError = true;
            state.isLoading = false;
            state.error = action.payload;
        });
    }
});

export default usersSlice.reducer;


