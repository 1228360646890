import {createSlice} from "@reduxjs/toolkit";

const languageSlice = createSlice({
    name: 'language',
    initialState: {language: localStorage.getItem('language') || 'ar'},
    reducers: {
        changeLanguage: (state, action)=>{
            if (action.payload === 'ar') {
                document.documentElement.setAttribute('dir', 'rtl');
            } else {
                document.documentElement.setAttribute('dir', 'ltr');
            }
            state.language = action.payload;
            localStorage.setItem('language', action.payload);
        }
    }
});

export const {changeLanguage} = languageSlice.actions;
export default languageSlice.reducer;