import axios from "axios";

const axiosInstance = axios.create({
    baseURL: "https://admin-baboon2.bhr.sa/api/",
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const refreshToken = localStorage.getItem('refresh_token') || sessionStorage.getItem('refresh_token');
                const response = await axios.post('https://admin-baboon2.bhr.sa/api/Users/refresh', {
                    token: localStorage.getItem('token') || sessionStorage.getItem('token'),
                    refreshToken: refreshToken,
                });

                const newToken = response.data.token;
                localStorage.setItem('token', newToken);

                originalRequest.headers.Authorization = `Bearer ${newToken}`;

                return axiosInstance(originalRequest);
            } catch (err) {
                console.error('Token refresh failed:', err);
                return Promise.reject(err);
            }
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
