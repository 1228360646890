import '../app/App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import { lazy, Suspense, useState, useMemo, useCallback, useEffect } from 'react';
import '../assets/fonts/fonts.css';
import classes from '../styles/sidebar.module.css';

import { ThemeProvider } from '../Context/ThemeContext'
import { useTranslation } from 'react-i18next';
import ReportDetails from '../pages/ReportDetails';
import Protected from '../components/Guards/Protected';
import Loader from '../components/Spinner/Loader';

const Login = lazy(() => import('../pages/Login'));
const Sidebar = lazy(() => import('../components/Sidebar/Sidebar'));
const Home = lazy(() => import('../pages/Home'));
const Users = lazy(() => import('../pages/Users'));
const Regions = lazy(() => import('../pages/Regions'));
const Notifications = lazy(() => import('../pages/Notifications'));
const Activities = lazy(() => import('../pages/Activities'));
const Profile = lazy(() => import('../pages/Profile'));
const Header = lazy(() => import('../components/Header/Header'));
const ReportsQuestions = lazy(() => import('../pages/ReportsQuestions'));
const AllReports = lazy(() => import('../pages/AllReports'));
const Tasks = lazy(() => import('../pages/Tasks'));
const AddReport = lazy(() => import('../pages/AddReport'));
const Footer = lazy(() => import('../components/Footer/Footer'));


function App() {
  const { i18n } = useTranslation();
  const location = useLocation();
  const isLoginPage = useMemo(() => location.pathname === '/', [location.pathname]);
  const [sidebarToggle, setSidebarToggle] = useState(false);

  const toggleSidebar = useCallback((inp) => {

    document.body.style.overflow = inp ? 'hidden' : 'auto';
    document.body.style.position = inp ? 'fixed' : 'static';
    document.body.style.height = inp ? '100vh' : 'auto';

    if (inp === false) {
      setSidebarToggle(inp);

    } else {
      setSidebarToggle((prev) => !prev);
    }

  }, []);

  useEffect(() => {
    const theme = localStorage.getItem('theme');
    if (theme === 'app-night') {
      document.body.style = 'background: black;';
    }
    else {
      document.body.style = 'background: white;';
    }
    i18n.changeLanguage(localStorage.getItem('language') || 'ar');
  }, [i18n])

  return (
    <ThemeProvider>
      <div className="app">
        {isLoginPage && (!localStorage.getItem('token') && !sessionStorage.getItem('token')) ? (
          <Suspense fallback={<Loader />}>
            <Login />
          </Suspense>
        ) : (
          <div className={classes.parent}>
            <div className={classes.sidebar_section}>
              <Suspense fallback={<div />}>
                <Sidebar sidebarToggle={sidebarToggle} setToggleSidebar={toggleSidebar} />
              </Suspense>
            </div>

            <div className={classes.header_section}>

              <div className={classes.header_content}>

                <Suspense fallback={<Loader />}>
                  <Header toggleSidebar={toggleSidebar} />
                </Suspense>

                <Routes>
                  <Route
                    path={localStorage.getItem('token') || sessionStorage.getItem('token') ? "/" : "/home"}
                    element={
                      <Protected>
                        <Suspense fallback={<Loader />}>
                          <Home />
                        </Suspense>
                      </Protected>
                    }
                  />
                  <Route
                    path="/reportsquestions"
                    element={
                      <Protected>
                        <Suspense fallback={<Loader />}>
                          <ReportsQuestions />
                        </Suspense>
                      </Protected>
                    }
                  />
                  <Route
                    path="/reportsquestions/addreport"
                    element={
                      <Protected>
                        <Suspense fallback={<Loader />}>
                          <AddReport />
                        </Suspense>
                      </Protected>
                    }
                  />
                  <Route
                    path="/allreports"
                    element={
                      <Protected>
                        <Suspense fallback={<Loader />}>
                          <AllReports />
                        </Suspense>
                      </Protected>
                    }
                  />
                  <Route
                    path='/report/details'
                    element={
                      <Protected>
                        <Suspense fallback={<Loader />}>
                          <ReportDetails />
                        </Suspense>
                      </Protected>
                    }
                  />
                  <Route
                    path="/users"
                    element={
                      <Protected>
                        <Suspense fallback={<Loader />}>
                          <Users />
                        </Suspense>
                      </Protected>
                    }
                  />
                  <Route
                    path="/areas"
                    element={
                      <Protected>
                        <Suspense fallback={<Loader />}>
                          <Regions />
                        </Suspense>
                      </Protected>
                    }
                  />
                  <Route
                    path="/notifications"
                    element={
                      <Protected>
                        <Suspense fallback={<Loader />}>
                          <Notifications />
                        </Suspense>
                      </Protected>
                    }
                  />
                  <Route
                    path="/activities"
                    element={
                      <Protected>
                        <Suspense fallback={<Loader />}>
                          <Activities />
                        </Suspense>
                      </Protected>
                    }
                  />
                  <Route
                    path="/profile"
                    element={
                      <Protected>
                        <Suspense fallback={<Loader />}>
                          <Profile />
                        </Suspense>
                      </Protected>
                    }
                  />
                  <Route
                    path="/tasks"
                    element={
                      <Protected>
                        <Suspense fallback={<Loader />}>
                          <Tasks />
                        </Suspense>
                      </Protected>
                    }
                  />
                </Routes>

              </div>

              <Suspense fallback={<Loader />}>
                <Footer />
              </Suspense>
            </div>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
