import { createSlice } from "@reduxjs/toolkit";

const sidebarSlice = createSlice({
    name: 'reportsToggle',
    initialState: {reportsToggle: false, isQuestion: false},
    reducers: {
        changeReportsToggle: (state, action)=>{
            state.reportsToggle = action.payload;
        },
        changeIsQuestion: (state, action)=>{
            state.isQuestion = action.payload;
        },
    }
});

export const {changeReportsToggle, changeIsQuestion} = sidebarSlice.actions;
export default sidebarSlice.reducer;