import { createContext, useState } from 'react';


// const theme = 'app-light';
const ThemeContext = createContext();

const themes = { light:'app-light', night: 'app-night'};
// const themes = { light: {style: 'app-light', isActive: true}, night: {style: 'app-night', isActive: false} };

const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(localStorage.getItem('theme') || themes.light);
    const [isLightActive, setIsLightActive] = useState(localStorage.getItem('theme')  === 'app-night' ? false : true);

    const toggleTheme = () => {
        if (theme === 'app-light') {
            document.body.style = 'background: black;';
            localStorage.setItem('theme', 'app-night');
            setTheme(themes.night);
            setIsLightActive(false);
        } 
        else {
            document.body.style = 'background: white;';
            localStorage.setItem('theme', 'app-light');
            setTheme(themes.light);
            setIsLightActive(true);
        };
    }

    return (
        <ThemeContext.Provider value={{theme , toggleTheme, isLightActive}}>
            {children}
        </ThemeContext.Provider>
    )
}

export {ThemeProvider, ThemeContext}



