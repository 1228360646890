import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../interceptor";

export const getCurrentUserAction = createAsyncThunk("getCurrentUser", async () => {
    try {
        const result = await axiosInstance.get('Users/me');
        return result.data;
    } catch (e) {
        console.log(e);
    }
});

export const updateCurrentUserAction = createAsyncThunk("updateCurrentUser", async (newData) => {
    try {
        const result = await axiosInstance.put('Users/my-data',newData);
        return result.data;
        
    } catch (e) {
        console.log(e);
    }
});

const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState: { currentUser: {} },
    extraReducers: (builder) => {
        builder.addCase(getCurrentUserAction.fulfilled, (state, action) => {
            state.currentUser = action.payload;
        });
        builder.addCase(updateCurrentUserAction.fulfilled, (state, action) => {
            state.currentUser = action.payload;
        });
    }
});

export default currentUserSlice.reducer;