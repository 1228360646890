import { configureStore } from "@reduxjs/toolkit";
import languageReducer from "./slices/languageSlice";
import authReducer from "./slices/authSlice";
import usersReducer from "./slices/usersSlice";
import regionsReducer from "./slices/regionsSlice";
import currentUserReducer from "./slices/currentUserSlice";
import reportsReducer from "./slices/reportsSlice";
import reportDetailsReducer from "./slices/reportDetailsSlice";
import sidebarReducer from "./slices/sidebarSlice";
import notificationsReducer from "./slices/notificationsSlice";
import resultsReducer from "./slices/resultsSlice";

const store = configureStore({
    reducer: {
        language: languageReducer,
        isAuth: authReducer,
        users: usersReducer,
        regions: regionsReducer,
        currentUser: currentUserReducer,
        reports: reportsReducer,
        reportDetails: reportDetailsReducer,
        reportsToggle: sidebarReducer,
        notifications: notificationsReducer,
        results: resultsReducer,
    }
});

export default store;