import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../interceptor";

export const getIncidentsAction = createAsyncThunk("getIncidents", async ({ regionId, from, to }) => {
    try {
        const result = await axiosInstance.get(`Dashboard/incidents?${regionId ? `RegionId=${regionId}&` : ''}${from ? `From=${from}&` : ''}${to ? `To=${to}&` : ''}`);
        return result.data;
    } catch (e) {
        console.log(e);
        return e;
    }
});

export const getMissionsAction = createAsyncThunk("getMissions", async ({ regionId, from, to }) => {
    try {
        const result = await axiosInstance.get(`Dashboard/missions?${regionId ? `RegionId=${regionId}&` : ''}${from ? `From=${from}&` : ''}${to ? `To=${to}&` : ''}`);
        return result.data;
    } catch (e) {
        console.log(e);
        return e;
    }
});

export const getRangersAction = createAsyncThunk("getRangers", async ({ regionId, from, to }) => {
    try {
        const result = await axiosInstance.get(`Dashboard/rangers?${regionId ? `RegionId=${regionId}&` : ''}${from ? `From=${from}&` : ''}${to ? `To=${to}&` : ''}`);
        return result.data;
    } catch (e) {
        console.log(e);
        return e;
    }
});

export const getIncidentsCompletedAction = createAsyncThunk("getIncidentsCompleted", async ({ regionId, from, to }) => {
    try {
        const result = await axiosInstance.get(`Dashboard/incidents-completed?${regionId ? `RegionId=${regionId}&` : ''}${from ? `From=${from}&` : ''}${to ? `To=${to}&` : ''}`);
        return result.data;
    } catch (e) {
        console.log(e);
        return e;
    }
});

const resultsSlice = createSlice({
    name: "incidents",
    initialState: { incidents: {}, missions: {}, rangers: {}, incidentsCompleted: {} },
    extraReducers: (builder) => {
        builder.addCase(getIncidentsAction.fulfilled, (state, action) => {
            state.incidents = action.payload;
        });
        builder.addCase(getMissionsAction.fulfilled, (state, action) => {
            state.missions = action.payload;
        });
        builder.addCase(getRangersAction.fulfilled, (state, action) => {
            state.rangers = action.payload;
        });
        builder.addCase(getIncidentsCompletedAction.fulfilled, (state, action) => {
            state.incidentsCompleted = action.payload;
        });
    }
});

export default resultsSlice.reducer;