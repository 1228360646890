import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../interceptor";

export const getReportsAction = createAsyncThunk("getReports", async ({ name, status, role, from, to, page, pageSize }) => {
    try {
        const result = await axiosInstance.get(`Incidents/admin?${name ? `Name=${name}&` : ''}${status ? `Status=${status}&` : ''}${role ? `Role=${role}&` : ''}${from ? `From=${from}&` : ''}${to ? `To=${to}&` : ''}Page=${page}&PageSize=${pageSize}`);
        return result.data;
    } catch (e) {
        console.log(e);
        return e;
    }
});

const reportsSlice = createSlice({
    name: "reports",
    initialState: { reports: {}, isLoading: true, isError: false },
    extraReducers: (builder) => {
        builder.addCase(getReportsAction.fulfilled, (state, action) => {
            state.reports = action.payload;
            state.isLoading = false;
            state.isError = false;
        });
        builder.addCase(getReportsAction.rejected, (state, action) => {
            state.isError = true;
            // if (action.payload.response.status === 401) {
            //     state.isError = true;
            //     state.isLoading = false;
            // }
            // else {
            //     state.isLoading = true;
            //     state.isError = false;
            // }
        });
    }
});

export default reportsSlice.reducer;