import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const customIcon = new L.Icon({
  iconUrl: '/icons/report-details/marker.png',
  iconSize: [28, 34],
  popupAnchor: [1, -12],
});

const MapComponent = ({lat, lng, name}) => {
  const position = [lat, lng]; 

  return (
    <MapContainer center={position} zoom={13} style={{ height: '132px', width: '260px', borderRadius: '10px' }} attributionControl={false}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position} icon={customIcon}>
        <Popup>
          <div style={{fontSize: '12px'}}>{name}</div>
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default MapComponent;
