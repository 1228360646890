import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "isAuth",
    initialState: { isAuth: localStorage.getItem('token') || sessionStorage.getItem('token') ? true : false },
    reducers: {
        changeAuth: (state, action) => {
            state.isAuth = action.payload;
        }
    }
});

export const { changeAuth } = authSlice.actions;
export default authSlice.reducer;