import { Oval } from 'react-loader-spinner';
import classes from '../../styles/loader.module.css'

const Loader = () => {
    return (
        <div className={classes.loader_container}>
            <Oval
                height={40}
                width={40}
                color="#B65535"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#E0DFDE"
                strokeWidth={2}
                strokeWidthSecondary={2}
            />
        </div>
    );
};

export default Loader;
