import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Protected = ({ children }) => {
    const isAuth = useSelector(state => state.isAuth.isAuth);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuth) {
            navigate('/');
        }
    }, [isAuth, navigate]);

    if (isAuth) {
        return children;
    } else {
        return null;
    }
};

export default Protected;
