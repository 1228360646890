import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ENTranslation from './locale/en.json';
import ARTranslation from './locale/ar.json';

const resources = {
  en: {
    translation: ENTranslation
  },
  ar: {
    translation: ARTranslation
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", 

    interpolation: {
      escapeValue: false 
    }
  });

  export default i18n;