import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../interceptor";

export const getNotificationsAction = createAsyncThunk("getNotifications", async ({ page, pageSize }) => {
    try {
        const result = await axiosInstance.get(`Notification?Page=${page}&PageSize=${pageSize}`);
        return result.data;
    } catch (e) {
        console.log(e);
    }
});

export const addNotificationAction = createAsyncThunk("addNotifications", async ({title, sendToRole, sendDate}) => {
    try {
        const result = await axiosInstance.post("Notification", {title: title, text: title, sendToRole: sendToRole, sendDate: sendDate});
        return result.data;
    } catch (e) {
        console.log(e);
    }
});



const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: { notifications: [], isLoading: true, isError: false, isPostSuccess: false },
    extraReducers: (builder) => {
        builder.addCase(getNotificationsAction.fulfilled, (state, action) => {
            state.notifications = action.payload;
            state.isLoading = false;
            state.isError = false;
        });
        // builder.addCase(getNotificationsAction.rejected, (state, action) => {
        //     state.isError = true;
        // });
        builder.addCase(addNotificationAction.fulfilled, (state, action) => {
            state.isPostSuccess = true;
        });
    },
    reducers: {
        setNotificationsNull : (state, action) => {
            state.notifications = [];
            state.isLoading = true
        },
        setIsPostFalse : (state, action) => {
            state.isPostSuccess = false
        }
    }
});

export const {setNotificationsNull, setIsPostFalse} = notificationsSlice.actions;

export default notificationsSlice.reducer;