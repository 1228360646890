import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../interceptor";

export const getRegionsAction = createAsyncThunk("getRegions", async ({ page, pageSize }) => {
    try {
        const result = await axiosInstance.get(`Regions?Page=${page}&PageSize=${pageSize}`);
        return result.data;
    } catch (e) {
        console.log(e);
    }
});

export const getAllRegionsAction = createAsyncThunk("getAllRegions", async () => {
    try {
        const result = await axiosInstance.get(`Regions`);
        return result.data;
    } catch (e) {
        console.log(e);
    }
});

export const addRegionAction = createAsyncThunk("addRegion", async (region) => {
    try {
        console.log(region);
        const result = await axiosInstance.post("Regions", region);
        return result;
    } catch (e) {
        console.log(e);
    }
});

export const updateRegionAction = createAsyncThunk("updateRegion", async ({id, region}) => {
    try {
        const result = await axiosInstance.put(`Regions/${id}`, region);
        return result.data;
    } catch (e) {
        console.log(e);
    }
});

export const deleteRegionAction = createAsyncThunk("deleteRegion", async (id) => {
    try {
        const result = await axiosInstance.delete(`Regions/${id}`);
        return result.data;
    } catch (e) {
        console.log(e);
    }
});

const regionsSlice = createSlice({
    name: 'regions',
    initialState: { regions: [], isLoading: true, isError: false, allRegions: [] },
    extraReducers: (builder) => {
        builder.addCase(getRegionsAction.fulfilled, (state, action) => {
            state.regions = action.payload;
            state.isLoading = false;
            state.isError = false;
        });
        builder.addCase(getRegionsAction.rejected, (state, action) => {
            state.isError = true;
        });
        builder.addCase(getAllRegionsAction.fulfilled, (state, action) => {
            state.allRegions = action.payload;
        });
    }
});

export default regionsSlice.reducer;